import React from 'react';

import { useNavigate } from 'react-router-dom';
import {
  FetchDataDownloading,
  GridProvider,
  GridResponse,
  GridTable,
  GridToolbar,
  useFetchData
} from '@homesusa/grid-table';

import { PhotographerService } from 'core/services';
import { Photographer } from 'core/interfaces';

import { usePhotographersColumns } from '../hooks';

export const PhotographersGrid = (): JSX.Element => {
  const navigate = useNavigate();
  const { getPhotographers } = PhotographerService;
  const columns = usePhotographersColumns();
  const defaultSortBy = '+firstname';
  const getPhotographer = ({ row }: { row: Photographer }): void => {
    navigate(`/photographers/${row.id}`);
  };

  const getGridData = React.useCallback(
    ({
      pageSize,
      pageIndex,
      sortBy,
      globalFilter,
      isForDownloading
    }: FetchDataDownloading): Promise<GridResponse<Photographer>> => {
      return getPhotographers({
        take: pageSize,
        skip: pageIndex,
        searchBy: globalFilter,
        sortBy: sortBy ?? defaultSortBy,
        isForDownloading
      });
    },
    []
  );

  const { fetchData, fetchDataForDownloading, data } = useFetchData<Photographer>(getGridData);

  return (
    <GridProvider
      options={{ columns: columns, data: data.data }}
      fetchData={fetchData}
      totalRows={data.total}
    >
      <GridToolbar title="Photographers">
        <GridToolbar.RefreshButton />
        <GridToolbar.ExportButtons onClick={fetchDataForDownloading} fileName="photographers" />
        <GridToolbar.ColumnVisibilityButton />
        <GridToolbar.SearchFilter />
      </GridToolbar>
      <GridTable onRowClick={getPhotographer} />
    </GridProvider>
  );
};
